export const STORAGE_KEY = 'CyStack'

let syncedData = {
    language: 'vi'
}

// Sync with local storage.
if (localStorage.getItem(STORAGE_KEY)) {
  var syncedDataLocal = JSON.parse(localStorage.getItem(STORAGE_KEY))
}

// Merge data and export it.
export const state = Object.assign({}, syncedData, syncedDataLocal)
