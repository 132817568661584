<template>
  <div id="map" style="background:#191a30;">
    <div class="logo-overlay">
      <a href="https://cystack.net/" class="m-brand__logo-wrapper _clickable" target="_blank">
        <img alt="" src="../assets/img/logo/attack-logo.svg" class="m-brand__logo-default" style="height: 50px"/>
      </a>
    </div>
    <div class="menu-overlay d-xl-block d-none">
      <router-link tag="div" :to="{name:'realtime'}" class="_clickable"
                   style="font-size: 20px; color: #ffffff; margin-bottom: 20px; font-weight: 400">{{
        $t("common.nav.realtime") }}
      </router-link>
      <router-link tag="div" :to="{name:'home'}" class="_clickable"
                   style="font-size: 20px; color: #C6DBEF; margin-bottom: 20px; font-weight: 300">{{
        $t("common.nav.statistics") }}
      </router-link>
      <router-link tag="div" :to="{name:'widget'}" class="_clickable"
                   style="font-size: 20px; color: #C6DBEF; margin-bottom: 20px; font-weight: 300">{{
        $t("common.nav.widget") }}
      </router-link>
      <div class="_clickable" style="font-size: 20px; color: #C6DBEF; margin-bottom: 20px; font-weight: 300"
           @click="aboutDialog=true">{{ $t("common.nav.about") }}
      </div>
      <div class="_clickable" style="font-size: 20px; color: #C6DBEF; margin-bottom: 20px; font-weight: 300"
           @click="goCyStack">Protect your website
      </div>


      <!--<el-dropdown trigger="click" @command="goToRouter">-->
      <!--<span class="el-dropdown-link">-->
      <!--<i class="flaticon-grid-menu _clickable"></i>-->
      <!--</span>-->
      <!--<el-dropdown-menu slot="dropdown">-->
      <!--<el-dropdown-item command="realtime">-->
      <!--<router-link tag="span"-->
      <!--:to="{name:'realtime'}">{{ $t("common.nav.realtime") }}</router-link>-->
      <!--</el-dropdown-item>-->
      <!--<el-dropdown-item divided="" command="home">-->
      <!--<router-link tag="span"-->
      <!--:to="{name:'home'}">{{ $t("common.nav.statistics") }}</router-link>-->
      <!--</el-dropdown-item>-->
      <!--<el-dropdown-item divided command="about">{{ $t("common.nav.about") }}</el-dropdown-item>-->
      <!--</el-dropdown-menu>-->
      <!--</el-dropdown>-->
    </div>
    <div class="menu-overlay-right d-xl-none d-block">
      <el-dropdown trigger="click" @command="goToRouter">
            <span class="el-dropdown-link">
            <i class="flaticon-grid-menu _clickable"></i>
            </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="realtime">
            <router-link tag="span"
                         :to="{name:'realtime'}">{{ $t("common.nav.realtime") }}
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item divided="" command="home">
            <router-link tag="span"
                         :to="{name:'home'}">{{ $t("common.nav.statistics") }}
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item divided="" command="widget">
            <router-link tag="span"
                         :to="{name:'widget'}">{{ $t("common.nav.widget") }}
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item divided command="about">{{ $t("common.nav.about") }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="hello" ref="mapdiv">
    </div>
    <div class="row mx-0 ui-overlay d-xl-flex d-none">
      <div class="col-xl-2 pl-xl-0" id="counter">
        <div class="m-portlet m-portlet--fit m-portlet--head-sm ui-counter full-height">
          <div class="m-portlet__head px-3">
            <h3 class="m-auto m--font-normal">
              {{now}} (UTC)
            </h3>
          </div>

          <div class="text-center m-auto">
            <div class="attacks-counts mt-3">
              <div class="today">
                <div>Attacks today</div>
                <h2 id="totalAttacksToday">
                  <AnimatedInteger :value="generalCountToday"></AnimatedInteger>
                </h2>
              </div>
            </div>
            <div class="attacks-counts mt-3">
              <div class="today">
                <div>Attacks this month</div>
                <h2 id="totalAttacksToday">
                  <AnimatedInteger :value="generalCountMonth"></AnimatedInteger>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-xxl-2 ui-content pr-xl-0">
        <div class="m-portlet m-portlet--fit m-portlet--head-sm ui-datatable full-height no-shadow ">
          <div class="m-portlet__head px-3 border-xl-right">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="font-weight-normal">
                  Countries
                </h3>
              </div>
            </div>
            <div class="m-portlet__head-tools">
              <ul class="m-portlet__nav">
                <li class="m-portlet__nav-item">
                  <div class="m-portlet__nav-link m-portlet__nav-link--icon">
                    <el-tooltip class="item" effect="dark"
                                content="Top 10 countries hosting hacked websites in this month" placement="top">
                      <i class="fas fa-info-circle" style="vertical-align: middle; font-size: 1.1vw"></i>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="px-3">
            <table class="table table-borderless">
              <thead>
              <tr>
                <th class="font-weight-bold"></th>
                <th class="font-weight-bold"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="country in mapData.slice(0,10)" :key="country.id">
                <td class="one-line">
                  <img :src="`/theme/images/flags/${country.id.toLowerCase()}.svg`" :alt="country.id"
                         style="width: 1.1vw;">
                  <span
                    style="margin-left: 1rem">{{getCountryNameFromCode(country.id)}}
                  </span>
                </td>
                <td>
                  <AnimatedInteger :value="country.value"></AnimatedInteger>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-xxl-2 ui-content px-xl-0">
        <div class="m-portlet m-portlet--fit m-portlet--head-sm ui-datatable full-height no-shadow ">
          <div class="m-portlet__head px-3 border-xl-right">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="font-weight-normal">
                  Top Level Domains
                </h3>
              </div>
            </div>
            <div class="m-portlet__head-tools">
              <ul class="m-portlet__nav">
                <li class="m-portlet__nav-item">
                  <div class="m-portlet__nav-link m-portlet__nav-link--icon">
                    <el-tooltip class="item" effect="dark"
                                content="Top 10 domains used for hacked websites in this month" placement="top">
                      <i class="fas fa-info-circle" style="vertical-align: middle; font-size: 1.1vw"></i>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="px-3">
            <table class="table table-borderless">
              <thead>
              <tr>
                <th class="font-weight-bold"></th>
                <th class="font-weight-bold"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="domain in topLevelDomainData.slice(0,10)" :key="domain.name">
                <td class="one-line">.{{domain.name}}</td>
                <td>
                  <AnimatedInteger :value="domain.value"></AnimatedInteger>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-xxl-2 ui-content px-xl-0">
        <div class="m-portlet m-portlet--fit m-portlet--head-sm ui-datatable full-height no-shadow ">
          <div class="m-portlet__head px-3 border-xl-right">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="font-weight-normal">
                  CMS
                </h3>
              </div>
            </div>
            <div class="m-portlet__head-tools">
              <ul class="m-portlet__nav">
                <li class="m-portlet__nav-item">
                  <div class="m-portlet__nav-link m-portlet__nav-link--icon">
                    <el-tooltip class="item" effect="dark"
                                content="Top 10 content management systems used for hacked websites in this month"
                                placement="top">
                      <i class="fas fa-info-circle" style="vertical-align: middle; font-size: 1.1vw"></i>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="px-3">
            <table class="table table-borderless">
              <thead>
              <tr>
                <th class="font-weight-bold"></th>
                <th class="font-weight-bold"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="cms in cmsData.slice(0,10)" :key="cms.name">
                <td class="one-line">
                  <img :src="`/theme/images/icons/${cms.icon}`" :alt="cms.icon"
                         style="width: 16px; height: 16px">
                  <span style="margin-left: 1rem;">
                    {{cms.name}}
                  </span>
                </td>
                <td>
                  <AnimatedInteger :value="cms.value"></AnimatedInteger>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-xxl-2 ui-content px-xl-0">
        <div class="m-portlet m-portlet--fit m-portlet--head-sm ui-datatable full-height no-shadow ">
          <div class="m-portlet__head px-3 border-xl-right">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="font-weight-normal">
                  Operating Systems
                </h3>
              </div>
            </div>
            <div class="m-portlet__head-tools">
              <ul class="m-portlet__nav">
                <li class="m-portlet__nav-item">
                  <div class="m-portlet__nav-link m-portlet__nav-link--icon">
                    <el-tooltip class="item" effect="dark"
                                content="Top 10 operating systems used for hacked websites in this month"
                                placement="top">
                      <i class="fas fa-info-circle" style="vertical-align: middle; font-size: 1.1vw"></i>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="px-3">
            <table class="table table-borderless">
              <thead>
              <tr>
                <th class="font-weight-bold"></th>
                <th class="font-weight-bold"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="os in systemData.slice(0,10)" :key="os.name">
                <td class="one-line">
                  <img :src="`/theme/images/icons/os/${getIcon(os.name)}`" :alt="os.icon"
                         style="width: 16px; height: 16px">
                  <span style="margin-left: 1rem"> {{os.name}}</span>
                </td>
                <td>
                  <AnimatedInteger :value="os.value"></AnimatedInteger>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-xxl-2 ui-content px-xl-0">
        <div class="m-portlet m-portlet--fit m-portlet--head-sm ui-datatable full-height no-shadow">
          <div class="m-portlet__head px-3">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="font-weight-normal">
                  Languages
                </h3>
              </div>
            </div>
            <div class="m-portlet__head-tools">
              <ul class="m-portlet__nav">
                <li class="m-portlet__nav-item">
                  <div class="m-portlet__nav-link m-portlet__nav-link--icon">
                    <el-tooltip class="item" effect="dark"
                                content="Top 10 programming languages used for hacked websites in this month"
                                placement="top">
                      <i class="fas fa-info-circle" style="vertical-align: middle; font-size: 1.1vw"></i>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="px-3">
            <table class="table table-borderless">
              <thead>
              <tr>
                <th class="font-weight-bold"></th>
                <th class="font-weight-bold"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="lang in languageData.slice(0,10)" :key="lang.name">
                <td class="one-line">
                  <img :src="`/theme/images/icons/${lang.icon}`" :alt="lang.icon"
                         style="width: 16px; height: 16px">
                  <span style="margin-left: 1rem"> {{lang.name}}</span>
                </td>
                <td>
                  <AnimatedInteger :value="lang.value"></AnimatedInteger>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="" :fullscreen="detectmobile()" :visible.sync="aboutDialog" custom-class="bg-about">
      <div slot="title">
        <img src="../assets/img/logo/attack-logo.svg" alt="" style="height: 50px">
      </div>
      <About></About>
    </el-dialog>
  </div>
</template>

<script>
  import * as am4core from "@amcharts/amcharts4/core"
  import * as am4maps from "@amcharts/amcharts4/maps"
  import am4geodata_worldHigh from "@amcharts/amcharts4-geodata/worldHigh"
  import am4themes_animated from "@amcharts/amcharts4/themes/animated"
  import am4themes_dark from "@amcharts/amcharts4/themes/dark"
  import * as moment from "moment"
  import CONSTANT from "../constant"
  import AnimatedInteger from "./AnimatedInteger"
  import About from "../views/About"

  am4core.useTheme(am4themes_animated);
  am4core.useTheme(am4themes_dark);

  export default {
    name: 'MapChart',
    data() {
      return {
        chart: null,
        map: null,
        moment: moment,
        loadingMap: false,
        mapData: [],
        topLevelDomainData: [],
        cmsData: [],
        systemData: [],
        range: 0,
        countries: [],
        languageData: [],
        now: moment(),
        generalCountMonth: 0,
        generalCountToday: 0,
        toDayStart: moment().utc().startOf('day').unix(),
        toDayEnd: moment().utc().endOf('day').unix(),
        monthStart: moment().utc().startOf('month').unix(),
        monthEnd: moment().utc().endOf('month').unix(),
        intervalGet: null,
        polygonSeries: null,
        imageSeries: null,
        realtimeData: [],
        constTimeData: [],
        intervalGetRealtime: null,
        aboutDialog: false
      }
    },
    methods: {
      createMap() {
        // Create map instance
        var map = am4core.create(this.$refs.mapdiv, am4maps.MapChart);

        // Set map definition
        map.geodata = am4geodata_worldHigh;

        // Set projection
        map.projection = new am4maps.projections.Miller();

        // Create map polygon series
        var polygonSeries = map.series.push(new am4maps.MapPolygonSeries());

        // Make map load polygon (like country names) data from GeoJSON
        polygonSeries.useGeodata = true;
        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        polygonTemplate.fill = am4core.color("#C6DBEF");
        polygonTemplate.adapter.add("stroke", function (fill, target) {
          return am4core.color("#E2EDFF")
        });

        polygonTemplate.strokeWidth = 0.8;
        // Create hover state and set alternative fill color
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#6177E4");

        // Remove Antarctica
        // polygonSeries.exclude = ["AQ"]

        // Add heat rule
        polygonSeries.heatRules.push({
          "property": "fill",
          "target": polygonSeries.mapPolygons.template,
          "min": am4core.color("#aacaff"),
          "max": am4core.color("#0363ff")
        });

        map.chartContainer.wheelable = true;
        map.chartContainer.draggable = false;
        map.chartContainer.swipeable = false;
        map.seriesContainer.events.on("dragstop", function (event) {
          map.goHome()
        }, this);

        map.homeZoomLevel = 1;
        map.maxZoomLevel = 2;
        map.minZoomLevel = 0.8;
        map.fixedWidthGrid = true;


        this.map = map
        this.polygonSeries = polygonSeries

        let imageSeries = map.series.push(new am4maps.MapImageSeries());
        // let imageSeriesTemplate = imageSeries.mapImages.template;
        // let circle = imageSeriesTemplate.createChild(am4core.Circle);
        // circle.radius = 4;
        // circle.fill = am4core.color("#B27799");
        // circle.stroke = am4core.color("#FFFFFF");
        // circle.strokeWidth = 2;
        // circle.nonScaling = false;
        // circle.tooltipText = "{title}";

        this.imageSeries = imageSeries
        // Add expectancy data
        polygonSeries.data = JSON.parse(JSON.stringify(this.mapData));
        map.events.on( "mappositionchanged", this.updateCustomMarkers );

      },
      updateCustomMarkers( event ) {

        // go through all of the images
        this.imageSeries.mapImages.each((image) =>{
          // check if it has corresponding HTML element
          if (!image.dummyData || !image.dummyData.externalElement) {
            // create onex
            image.dummyData = {
              externalElement: this.createCustomMarker(image)
            };
          }

          // reposition the element accoridng to coordinates
          var xy = this.map.geoPointToSVG( { longitude: image.longitude, latitude: image.latitude } );
          image.dummyData.externalElement.style.top = xy.y + 'px';
          image.dummyData.externalElement.style.left = xy.x + 'px';
        });

      },
      createCustomMarker( image ) {
        var chart = image.dataItem.component.chart;

        // create holder
        var holder = document.createElement( 'div' );
        holder.className = 'map-marker';
        //holder.title = image.dataItem.dataContext.title;
        holder.style.position = 'absolute';

        // maybe add a link to it?
        if ( undefined != image.url ) {
          holder.onclick = function() {
            window.location.href = image.url;
          };
          holder.className += ' map-clickable';
        }

        // create dot
        var dot = document.createElement( 'div' );
        dot.className = 'dot';
        holder.appendChild( dot );

        // create pulse
        var pulse = document.createElement( 'div' );
        pulse.className = 'pulse';
        holder.appendChild( pulse );

        // append the marker to the map container
        chart.svgContainer.htmlElement.appendChild( holder );

        return holder;
      },
      getRealtime(time) {
        this.loadingMap = true;
        return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/realtime?time=${time}`)
          .then(response => {
            // this.removeRandomLocation()
            //this.realtimeData = this.realtimeData.concat(response.data)
            // this.removeLocations()
            // this.realtimeData = response.data
            return response.data
          }).catch(function (error) {
            // handle error
            console.log(error)
          })
      },
      getMapData(from, to) {
        this.loadingMap = true;
        return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/country${from ? '?from=' + from + '&to=' + to : ''}`)
          .then(response => {
            this.mapData = response.data;
            this.mapData = this.mapData.filter((location) => {
              return location.name !== this.getCountryNameFromCode(location.name)
            });
            this.mapData = this.mapData.map((location) => {
              return {name: this.getCountryNameFromCode(location.name), id: location.name, value: location.value}
            });
          }).catch(function (error) {
            // handle error
            console.log(error)
          })
      },
      getTLDData(from, to) {
        this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/tld${from ? '?from=' + from + '&to=' + to : ''}`)
          .then(response => {
            this.topLevelDomainData = response.data
          }).catch(function (error) {
          // handle error
          console.log(error)
        })
      },
      getCMS(from, to) {
        this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/cms${from ? '?from=' + from + '&to=' + to : ''}`)
          .then(response => {
            this.cmsData = response.data
          }).catch(function (error) {
          // handle error
          console.log(error)
        })
      },
      getSystem(from, to) {
        this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/system${from ? '?from=' + from + '&to=' + to : ''}`)
          .then(response => {
            this.systemData = response.data
          }).catch(function (error) {
          // handle error
          console.log(error)
        })
      },
      getLanguage(from, to) {
        this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/language${from ? '?from=' + from + '&to=' + to : ''}`)
          .then(response => {
            this.languageData = response.data
          }).catch(function (error) {
          // handle error
          console.log(error)
        })
      },
      getServer(from, to) {
        this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/web_server${from ? '?from=' + from + '&to=' + to : ''}`)
          .then(response => {

          }).catch(function (error) {
          // handle error
          console.log(error)
        })
      },
      getGeneral(from, to) {
        return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/general${from ? '?from=' + from + '&to=' + to : ''}`)
          .then(response => {
            return response.data.amount
          }).catch(function (error) {
            // handle error
            console.log(error)
          })
      },
      getCountries() {
        return this.$http.get(`${CONSTANT.API_HREF_BASE}/resources/countries`)
          .then(response => {
            this.countries = response.data
          })
          .catch(function (error) {
            // handle error
            console.log(error)
          })
      },
      getCountryNameFromCode(code) {
        for (let i = 0; i < this.countries.length; i++) {
          if (this.countries[i].country_code.toLowerCase() === code.toLowerCase()) {
            return this.countries[i].country_name
          }
        }
        return code
      },
      displayTime() {
        this.now = moment().utc().format('HH:mm:ss');
        this.timeInterval = setTimeout(this.displayTime, 1000)
      },
      removeRandomLocation() {
        let x = Math.floor(Math.random() * this.realtimeData.length);
        for (let i = 0; i < x; i++) {
          if (this.realtimeData[i].location) {
            this.realtimeData[i].location.dispose()
          }
        }
        this.realtimeData.splice(0, x)
      },
      removeLocations() {
        this.imageSeries.disposeChildren()
        // this.realtimeData.forEach(element => {
        //     if (element.location) {
        //         element.location.dispose()
        //     }
        // })
      },
      createLocation() {
        this.constTimeData.forEach((element) => {
          element.location = this.imageSeries.mapImages.create();
          element.location.longitude = Number(element.lng)
          element.location.latitude = Number(element.lat)
          element.location.nonScaling = true;

          // No animate
          // element.bulletAlertCircle = element.location.createChild(am4core.Circle);
          // element.bulletAlertCircle.fill = am4core.color();
          // element.bulletAlertCircle.stroke = "#FF6473";
          // element.bulletAlertCircle.strokeOpacity = 1;
          // element.bulletAlertCircle.radius = 1;
          // element.bulletAlertCircle.strokeWidth = 5;
          // element.bulletAlertCircle.visible = true;
          // let bulletAlertAnimation = element.bulletAlertCircle.animate([{
          //   property: "radius",
          //   to: 30
          // }, {property: "strokeOpacity", to: 0, from: 1}], 2000).loop();
          //
          // let honoluluCircle = element.location.createChild(am4core.Circle);
          // honoluluCircle.fill = "#FF6473";
          // honoluluCircle.stroke = honoluluCircle.fill;
          // honoluluCircle.radius = 6;
        });
      },
      addLocation() {
        // for (let i=0; i < this.realtimeData.length; i++) {
        //     if(this.constTimeData[i].location) {
        //         this.constTimeData[i].location.dispose()
        //     }
        // }
        // this.constTimeData.splice(0, 5)

        this.realtimeData.forEach((element) => {
          element.location = this.imageSeries.mapImages.create();
          element.location.longitude = Number(element.lng)
          element.location.latitude = Number(element.lat)
          element.location.nonScaling = true;

          // element.bulletAlertCircle = element.location.createChild(am4core.Circle);
          // element.bulletAlertCircle.fill = am4core.color();
          // element.bulletAlertCircle.stroke = "#FF6473";
          // element.bulletAlertCircle.strokeOpacity = 1;
          // element.bulletAlertCircle.radius = 1;
          // element.bulletAlertCircle.strokeWidth = 5;
          // element.bulletAlertCircle.visible = true;
          // let bulletAlertAnimation = element.bulletAlertCircle.animate([{
          //   property: "radius",
          //   to: 30
          // }, {property: "strokeOpacity", to: 0, from: 1}], 2000).loop();
          //
          // let honoluluCircle = element.location.createChild(am4core.Circle);
          // honoluluCircle.fill = "#FF6473";
          // honoluluCircle.stroke = honoluluCircle.fill;
          // honoluluCircle.radius = 6;
        });
      },
      goToRouter(name) {
        if (name === 'about') {
          this.aboutDialog = true
        } else {
          this.$router.push({name: name})
        }
      },
      getIcon(name) {
        if (name.toLowerCase().indexOf('linux') > -1) return 'linux.svg'
        if (name.toLowerCase().indexOf('unknown') > -1) return 'unknown.svg'
        if (name.toLowerCase().indexOf('win') > -1) return 'windows.svg'
        if (name.toLowerCase().indexOf('freebsd') > -1) return 'freebsd.png'
        if (name.toLowerCase().indexOf('openbsd') > -1) return 'openbsd.gif'
        if (name.toLowerCase().indexOf('f5') > -1) return 'f5.svg'
        if (name.toLowerCase().indexOf('mac') > -1) return 'mac.svg'
        if (name.toLowerCase().indexOf('solaris') > -1) return 'solaris.svg'
        if (name.toLowerCase().indexOf('cisco') > -1) return 'cisco.png'
        return 'unknown.svg'
      },
      goCyStack() {
        window.open('https://cystack.io', '_blank');
      }
    },
    mounted() {
      this.getCountries().then(() => {
        this.getMapData(this.monthStart, this.monthEnd).then(() => {
          this.createMap()
          this.getRealtime(60 * 60 * 3).then((data) => {
            // this.removeLocations();
            this.constTimeData = data
            this.createLocation()
          })
        })
      });

      this.getTLDData(this.monthStart, this.monthEnd);
      this.getCMS(this.monthStart, this.monthEnd);
      this.getSystem(this.monthStart, this.monthEnd);
      this.getLanguage(this.monthStart, this.monthEnd);
      this.getGeneral(this.monthStart, this.monthEnd).then(response => {
        this.generalCountMonth = response
      });
      this.getGeneral(this.toDayStart, this.toDayEnd).then(response => {
        this.generalCountToday = response
      });

      this.displayTime();
      this.intervalGet = setInterval(() => {
        this.getMapData(this.monthStart, this.monthEnd).then(() => {
          // this.polygonSeries.data = this.mapData
        })
        this.getTLDData(this.monthStart, this.monthEnd);
        this.getCMS(this.monthStart, this.monthEnd);
        this.getSystem(this.monthStart, this.monthEnd);
        this.getLanguage(this.monthStart, this.monthEnd);
        this.getGeneral(this.monthStart, this.monthEnd).then(response => {
          this.generalCountMonth = response
        });
        this.getGeneral(this.toDayStart, this.toDayEnd).then(response => {
          this.generalCountToday = response
        });

        // this.getRealtime().then(() => {
        // 	this.createLocation()
        // })
      }, 10000)
      this.intervalGetRealtime = setInterval(() => {
        this.getRealtime(10).then((data) => {
          this.realtimeData = data
          this.addLocation()
          this.updateCustomMarkers()
        })
      }, 1000 * 10)
    },
    created() {

    },
    beforeDestroy() {
      if (this.chart) {
        this.chart.dispose()
      }
      if (this.map) {
        this.map.dispose()
      }
      clearTimeout(this.timeInterval)
      clearInterval(this.intervalGet)
      clearInterval(this.intervalGetRealtime)
    },
    components: {AnimatedInteger, About}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hello {
    width: 100%;
    height: 100vh;
  }

  .one-line {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 27px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .one-line img {
    margin-top: -3px;
  }
</style>
