<template>
    <!-- begin::Header -->
    <header id="m_header" class="m-grid__item m-header " m-minimize="minimize" m-minimize-mobile="minimize" m-minimize-offset="10" m-minimize-mobile-offset="10">
        <div class="m-header__top">
            <div class="m-container m-container--fluid m-container--full-height m-page__container">
                <div class="m-stack m-stack--ver m-stack--desktop">

                    <!-- begin::Brand -->
                    <div class="m-stack__item m-brand m-stack__item--left">
                        <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                            <div class="m-stack__item m-stack__item--middle m-brand__logo">
                                <a href="/" class="m-brand__logo-wrapper">
                                    <img alt="" src="../assets/img/logo/attack-logo.svg" class="m-brand__logo-default"  style="height: 50px"/>
                                    <img alt="" src="../assets/img/logo/logo.svg" class="m-brand__logo-inverse" style="height: 50px"/>
                                </a>
                            </div>
                            <div class="m-stack__item m-stack__item--middle m-brand__tools">

                                <!-- begin::Responsive Header Menu Toggler-->
                                <a id="m_aside_header_menu_mobile_toggle" href="javascript:;" class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
                                    <span></span>
                                </a>

                                <!-- end::Responsive Header Menu Toggler-->

                                <!-- begin::Topbar Toggler-->
                                <!--<a id="m_aside_header_topbar_mobile_toggle" href="javascript:;" class="m-brand__icon m&#45;&#45;visible-tablet-and-mobile-inline-block">-->
                                    <!--<i class="flaticon-more"></i>-->
                                <!--</a>-->

                                <!--end::Topbar Toggler-->
                            </div>
                        </div>
                    </div>

                    <!-- end::Brand -->

                    <!--begin::Search-->
                    <div class="m-stack__item m-stack__item--middle m-dropdown m-dropdown--arrow m-dropdown--large m-dropdown--mobile-full-width m-dropdown--align-right m-dropdown--skin-light m-header-search m-header-search--expandable- m-header-search--skin-" id="m_quicksearch"
                         m-quicksearch-mode="default">

                        <!--begin::Search Form -->
                        <!--<form class="m-header-search__form">-->
                            <!--<div class="m-header-search__wrapper">-->
										<!--<span class="m-header-search__icon-search" id="m_quicksearch_search">-->
											<!--<i class="la la-search"></i>-->
										<!--</span>-->
                                <!--<span class="m-header-search__input-wrapper">-->
											<!--<input autocomplete="off" type="text" name="q" class="m-header-search__input" value="" placeholder="Search..." id="m_quicksearch_input">-->
										<!--</span>-->
                                <!--<span class="m-header-search__icon-close" id="m_quicksearch_close">-->
											<!--<i class="la la-remove"></i>-->
										<!--</span>-->
                                <!--<span class="m-header-search__icon-cancel" id="m_quicksearch_cancel">-->
											<!--<i class="la la-remove"></i>-->
										<!--</span>-->
                            <!--</div>-->
                        <!--</form>-->

                        <!--end::Search Form -->

                        <!--begin::Search Results -->
                        <div class="m-dropdown__wrapper">
                            <div class="m-dropdown__arrow m-dropdown__arrow--center"></div>
                            <div class="m-dropdown__inner">
                                <div class="m-dropdown__body">
                                    <div class="m-dropdown__scrollable m-scrollable" data-scrollable="true" data-height="300" data-mobile-height="200">
                                        <div class="m-dropdown__content m-list-search m-list-search--skin-light">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--end::Search Results -->
                    </div>

                    <!--end::Search-->

                    <!-- begin::Topbar -->
                    <div class="m-stack__item m-stack__item--right m-header-head" id="m_header_nav">
                        <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
                            <div class="m-stack__item m-topbar__nav-wrapper">
                                <ul class="m-topbar__nav m-nav m-nav--inline">
                                    <li class="m-nav__item m-brand__tools">
                                        <div class="middle-item">
                                            <el-date-picker
                                                    class=""
                                                    v-model="dateTimePicker"
                                                    type="datetimerange"
                                                    range-separator="To"
                                                    start-placeholder="Start date"
                                                    end-placeholder="End date"
                                                    :picker-options="pickerOptions">
                                            </el-date-picker>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- end::Topbar -->
                </div>
            </div>
        </div>
        <div class="m-header__bottom" style="height: 0;">

        </div>
    </header>

    <!-- end::Header -->
</template>

<script>
    export default {
        name: 'Header',
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: 'Last week',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: 'Last month',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: 'Last 3 months',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                dateTimePicker:  null
            }
        }
    }
</script>
