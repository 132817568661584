<template>
    <transition name="component-fade" mode="out-in">
        <router-view>
            <!-- Content go here -->
        </router-view>
    </transition>
</template>
<script>
    export default {
        name: 'App'
    }
</script>
<style>
</style>
