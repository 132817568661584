<template>
  <!-- begin:: Page -->
  <div class="m-grid m-grid--hor m-grid--root m-page" style="background:#191a30;">
    <!-- begin::Header -->
    <!-- begin::Header -->
    <header id="m_header" class="m-grid__item m-header " m-minimize="minimize" m-minimize-mobile="minimize"
            m-minimize-offset="10" m-minimize-mobile-offset="10">
      <div class="m-header__top">
        <div class="m-container m-container--fluid m-container--full-height m-page__container">
          <div class="m-stack m-stack--ver m-stack--desktop">

            <!-- begin::Brand -->
            <div class="m-stack__item m-brand m-stack__item--left">
              <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                <div class="m-stack__item m-stack__item--middle m-brand__logo">
                  <a href="https://cystack.net/" class="m-brand__logo-wrapper" target="_blank">
                    <img alt="" src="../assets/img/logo/attack-logo.svg"
                         class="m-brand__logo-default" style="height: 50px"/>
                    <img alt="" src="../assets/img/logo/attack-logo.svg"
                         class="m-brand__logo-inverse"
                         style="height: 50px"/>
                  </a>
                </div>
                <div class="m-stack__item m-stack__item--middle m-brand__tools">

                  <!-- begin::Responsive Header Menu Toggler-->
                  <a id="m_aside_header_menu_mobile_toggle" href="javascript:;"
                     class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
                    <span></span>
                  </a>

                  <!-- end::Responsive Header Menu Toggler-->

                  <!-- begin::Topbar Toggler-->
                  <!--<a id="m_aside_header_topbar_mobile_toggle" href="javascript:;"-->
                  <!--class="m-brand__icon m&#45;&#45;visible-tablet-and-mobile-inline-block">-->
                  <!--<i class="flaticon-more"></i>-->
                  <!--</a>-->

                  <!--end::Topbar Toggler-->
                </div>
              </div>
            </div>
            <!-- ed::Brand -->

            <div class="m-stack__item m-stack__item--middle m-stack__item--fluid" style="width: 25%">
              <div id="m_header_menu"
                   class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-dark m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light">
                <ul class="m-menu__nav  m-menu__nav--submenu-arrow">
                  <router-link tag="li" class="m-menu__item" :to="{ name: 'realtime'}"
                               active-class="m-menu__item--active" aria-haspopup="true" :exact="true">
                    <a class="m-menu__link ">
                      <span class="m-menu__item-here"></span>
                      <span class="m-menu__link-text">
													{{ $t("common.nav.realtime") }}
                                            </span>
                    </a>
                  </router-link>
                  <router-link tag="li" class="m-menu__item" :to="{ name: 'home'}"
                               active-class="m-menu__item--active" aria-haspopup="true">
                    <a class="m-menu__link ">
                      <span class="m-menu__item-here"></span>
                      <span class="m-menu__link-text">
													{{ $t("common.nav.statistics") }}
                                            </span>
                    </a>
                  </router-link>
                  <router-link tag="li" class="m-menu__item" :to="{ name: 'widget'}"
                               active-class="m-menu__item--active" aria-haspopup="true">
                    <a class="m-menu__link ">
                      <span class="m-menu__item-here"></span>
                      <span class="m-menu__link-text">
													{{ $t("common.nav.widget") }}
                                            </span>
                    </a>
                  </router-link>
                  <li class="m-menu__item" @click="aboutDialog=true">
                    <a class="m-menu__link ">
                      <span class="m-menu__item-here"></span>
                      <span class="m-menu__link-text">
													{{ $t("common.nav.about") }}
                                            </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <!-- begin::Topbar -->
            <div class="m-stack__item m-stack__item--right m-header-head" id="m_header_nav"
                 style="width: 60%">
              <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
                <div class="m-stack__item m-topbar__nav-wrapper">
                  <ul class="m-topbar__nav m-nav m-nav--inline">
                    <li class="m-nav__item m-brand__tools">
                      <div class="middle-item count-title">
                        <AnimatedInteger :value.sync="amount"></AnimatedInteger>
                        Attacks
                        <el-tooltip placement="bottom" popper-class="_clickable">
                          <div slot="content" class="">
                            <input :value.sync="amountDetail" class="d-none">
                            <div v-for="data in amountDetail" class="pb-2">
                              <span class="text-capitalize">{{data.name}}:</span> {{data.value | formatNumber}}
                            </div>
                          </div>
                          <i class="fas fa-info-circle"></i>
                        </el-tooltip>
                      </div>
                    </li>
                    <li class="m-nav__item m-brand__tools">
                      <div class="middle-item">
                        <el-date-picker
                          class=""
                          v-model="dateTimePicker"
                          type="daterange"
                          range-separator="-"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          :picker-options="pickerOptions"
                          :clearable="false">
                        </el-date-picker>
                      </div>
                    </li>
                    <li class="m-nav__item m-topbar__user-profile  m-dropdown m-dropdown--medium m-dropdown--arrow  m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light _clickable"
                        :class="statusFilter?'m-dropdown--open':''">
                      <a class="m-nav__link m-dropdown__toggle" @click="toggleFilter">
                                                <span class="m-nav__link-icon m-topbar__usericon">
                                                    <span class="m-nav__link-icon-wrapper">
                                                        <i class="fas fa-filter"></i>
                                                    </span>
                                                </span>
                      </a>
                      <div class="m-dropdown__wrapper">
                        <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                        <div class="m-dropdown__inner">
                          <div class="m-dropdown__body">
                            <div class="m-dropdown__content">
                              <ul class="m-nav m-nav--skin-light">
                                <li class="m-nav__item">
                                  <el-select class="full-width" v-model="filter.country"
                                             filterable>
                                    <el-option label="All countries" value="">

                                    </el-option>
                                    <el-option
                                      v-for="data in dataCountry"
                                      :key="data.name"
                                      :label="getCountryNameFromCode(data.name)"
                                      :value="data.name">
                                      <img :src="`/theme/images/flags/${data.name.toLowerCase()}.svg`"
                                           alt="icon"
                                           height="20px" width="30px">
                                      <span class="ml-2 text-inside-select">{{getCountryNameFromCode(data.name)}}</span>
                                    </el-option>
                                  </el-select>
                                </li>
                                <li class="m-nav__separator m-nav__separator--fit">
                                </li>
                                <li class="m-nav__item">
                                  <el-select class="full-width" v-model="filter.tld"
                                             filterable>
                                    <el-option label="All TLDs" value="">

                                    </el-option>
                                    <el-option
                                      v-for="data in dataTLD"
                                      :key="data.name"
                                      :label="`.${data.name}`"
                                      :value="data.name">
                                    </el-option>
                                  </el-select>
                                </li>
                                <li class="m-nav__separator m-nav__separator--fit">
                                </li>
                                <li class="m-nav__item">
                                  <el-select class="full-width" v-model="filter.cms"
                                             filterable>
                                    <el-option label="All CMS" value="">

                                    </el-option>
                                    <el-option
                                      v-for="data in dataCMS"
                                      :key="data.name"
                                      :label="data.name"
                                      :value="data.name">
                                      <img :src="`/theme/images/icons/${data.icon}`" :alt="data.icon"
                                           style="width: 16px; height: 16px">
                                      <span class="ml-2 text-inside-select">{{data.name}}</span>
                                    </el-option>
                                  </el-select>
                                </li>
                                <li class="m-nav__separator m-nav__separator--fit">
                                </li>
                                <li class="m-nav__item">
                                  <el-select class="full-width" v-model="filter.system"
                                             filterable>
                                    <el-option label="All Systems" value="">

                                    </el-option>
                                    <el-option
                                      v-for="data in dataSystem"
                                      :key="data.name"
                                      :label="data.name"
                                      :value="data.name">
                                      <img :src="`/theme/images/icons/os/${getIcon(data.name)}`" :alt="data.name"
                                           style="width: 16px; height: 16px">
                                      <span class="ml-2 text-inside-select">{{data.name}}</span>
                                    </el-option>
                                  </el-select>
                                </li>
                                <li class="m-nav__separator m-nav__separator--fit">
                                </li>
                                <li class="m-nav__item">
                                  <el-select class="full-width" v-model="filter.web_server"
                                             filterable>
                                    <el-option label="All Web servers" value="">

                                    </el-option>
                                    <el-option
                                      v-for="data in dataServer"
                                      :key="data.name"
                                      :label="data.name"
                                      :value="data.name">
                                      <img :src="`/theme/images/icons/${data.icon}`" :alt="data.icon"
                                           style="width: 16px; height: 16px">
                                      <span class="ml-2 text-inside-select">{{data.name}}</span>
                                    </el-option>
                                  </el-select>
                                </li>
                                <li class="m-nav__separator m-nav__separator--fit">
                                </li>
                                <li class="m-nav__item">
                                  <el-select class="full-width" v-model="filter.language"
                                             filterable>
                                    <el-option label="All Languages" value="">

                                    </el-option>
                                    <el-option
                                      v-for="data in dataLanguage"
                                      :key="data.name"
                                      :label="data.name"
                                      :value="data.name">
                                      <img :src="`/theme/images/icons/${data.icon}`" :alt="data.icon"
                                           style="width: 16px; height: 16px">
                                      <span class="ml-2 text-inside-select">{{data.name}}</span>
                                    </el-option>
                                  </el-select>
                                </li>
                                <li class="m-nav__separator m-nav__separator--fit">
                                </li>
                                <li class="m-nav__item">
                                  <button
                                    class="btn btn-sm btn-secondary m-btn  m-btn--label-brand m-btn--bolder pull-right ml-2"
                                    @click="applyFilter">Apply
                                  </button>
                                  <button
                                    class="btn btn-sm btn-secondary m-btn m-btn--label-metal  m-btn--bolder pull-right"
                                    @click="toggleFilter">Cancel
                                  </button>
                                  <div class="clearfix"></div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- end::Topbar -->
          </div>
        </div>
      </div>
      <div class="m-header__bottom" style="height: 0;">

      </div>
    </header>

    <!-- end::Header -->

    <!-- end::Header -->
    <!-- begin::Body -->
    <div class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop m-page__container m-body"
         style="background:#191a30;">
      <div class="m-grid__item m-grid__item--fluid m-wrapper" style="background: transparent">
        <MapChart :dateTimePicker="dateTimePicker"
                  v-bind:filter.sync="filter"
                  v-bind:amount.sync="amount"
                  v-bind:amountDetail.sync="amountDetail"
                  v-bind:dataCountry.sync="dataCountry"
                  v-bind:dataTLD.sync="dataTLD"
                  v-bind:dataCMS.sync="dataCMS"
                  v-bind:dataSystem.sync="dataSystem"
                  v-bind:dataServer.sync="dataServer"
                  v-bind:dataLanguage.sync="dataLanguage"
                  v-bind:statusFilter.sync="statusFilter"
                  ref="statisticsComponent"
        />
      </div>
      <el-dialog title="" :visible.sync="aboutDialog" custom-class="bg-about" :fullscreen="detectmobile()">
        <div slot="title">
          <img src="../assets/img/logo/attack-logo.svg" alt="" style="height: 50px">
        </div>
        <About></About>
      </el-dialog>
    </div>

    <!-- begin::Footer -->
    <footer class="m-grid__item m-footer " style="background:#191a30;">
      <div class="m-container m-container--fluid m-container--full-height m-page__container">
        <div class="m-footer__wrapper">
          <div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
            <div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
								<span class="m-footer__copyright">
                                    Copyright © 2018
									<a href="https://cystack.net" target="_blank" class="m-link">CyStack</a>. All rights reserved.
								</span>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <!-- end::Footer -->
    <script2 src="/theme/vendors.bundle.js"></script2>
    <script2 src="/theme/scripts.bundle.js"></script2>
  </div>
  <!-- end:: Page -->
</template>

<script>
  // @ is an alias to /src
  import MapChart from '@/components/MapChart.vue';
  import About from './About.vue'
  import AnimatedInteger from '../components/AnimatedInteger'
  import * as moment from "moment"
  import CONSTANT from "../constant";

  export default {
    name: 'home',
    data() {
      return {
        pickerOptions: {
          shortcuts: [{
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }],
          disabledDate(date) {
            return moment(date).isAfter(moment());
          }
        },
        dateTimePicker: [moment().utc().startOf('month'), moment().utc().endOf('day')],
        countries: [],
        dataCountry: [],
        dataTLD: [],
        dataCMS: [],
        dataSystem: [],
        dataServer: [],
        dataLanguage: [],
        aboutDialog: false,
        amount: 0,
        amountDetail: [],
        visible: false,
        statusFilter: false,
        filter: {
          country: "",
          tld: "",
          cms: "",
          system: "",
          web_server: "",
          language: "",
        }
      }
    },
    methods: {
      disabledDate(date) {
        return moment(date).isBefore(moment());
      },
      getCountries() {
        return this.$http.get(`${CONSTANT.API_HREF_BASE}/resources/countries`)
          .then(response => {
            this.countries = response.data
          })
          .catch(function (error) {
            // handle error
            console.log(error)
          })
      },
      getCountryNameFromCode(code) {
        for (let i = 0; i < this.countries.length; i++) {
          if (this.countries[i].country_code.toLowerCase() === code.toLowerCase()) {
            return this.countries[i].country_name
          }
        }
        return code
      },
      toggleFilter() {
        this.statusFilter = !this.statusFilter
      },
      getIcon(name) {
        if (name.toLowerCase().indexOf('linux') > -1) return 'linux.svg'
        if (name.toLowerCase().indexOf('unknown') > -1) return 'unknown.svg'
        if (name.toLowerCase().indexOf('win') > -1) return 'windows.svg'
        if (name.toLowerCase().indexOf('freebsd') > -1) return 'freebsd.png'
        if (name.toLowerCase().indexOf('openbsd') > -1) return 'openbsd.gif'
        if (name.toLowerCase().indexOf('f5') > -1) return 'f5.svg'
        if (name.toLowerCase().indexOf('mac') > -1) return 'mac.svg'
        if (name.toLowerCase().indexOf('solaris') > -1) return 'solaris.svg'
        if (name.toLowerCase().indexOf('cisco') > -1) return 'cisco.png'
        return 'unknown.svg'
      },
      applyFilter() {
        this.$refs.statisticsComponent.applyFilter()
      }
    },
    components: {
      MapChart, AnimatedInteger, About
    },
    created() {
      this.getCountries()
    }
  }
</script>
