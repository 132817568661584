<template>
    <!-- begin:: Page -->
    <div>
        <!-- begin::Header -->

        <!-- end::Header -->
        <!-- begin::Body -->
        <RealtimeScreen/>
        <!-- begin::Footer -->

        <!-- end::Footer -->
    </div>
    <!-- end:: Page -->
</template>

<script>
    // @ is an alias to /src
    import Header from '@/components/Header.vue'
    import RealtimeScreen from '@/components/RealtimeScreen.vue'

    export default {
        name: 'realtime',
        components: {
            Header, RealtimeScreen
        },
        created() {
        }
    }
</script>
