import app from '../main'
import * as moment from 'moment-timezone'

export const SET_LANG = (state, payload) => {
  if (payload === 'vi') {
    moment.locale('vi', {
      months : 'tháng 1_tháng 2_tháng 3_tháng 4_tháng 5_tháng 6_tháng 7_tháng 8_tháng 9_tháng 10_tháng 11_tháng 12'.split('_'),
      monthsShort : 'Th01_Th02_Th03_Th04_Th05_Th06_Th07_Th08_Th09_Th10_Th11_Th12'.split('_'),
      relativeTime : {
        future : '%s tới',
        past : '%s trước',
        s : 'Vài giây',
        m : '1 phút',
        mm : '%d phút',
        h : '1 giờ',
        hh : '%d giờ',
        d : '1 ngày',
        dd : '%d ngày',
        M : '1 tháng',
        MM : '%d tháng',
        y : '1 năm',
        yy : '%d năm'
      },
      longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM [năm] YYYY',
        LLL : 'D MMMM [năm] YYYY HH:mm',
        LLLL : 'dddd, D MMMM [năm] YYYY HH:mm',
        l : 'DD/M/YYYY',
        ll : 'D MMM YYYY',
        lll : 'D MMM YYYY HH:mm',
        llll : 'ddd, D MMM YYYY HH:mm'
      },
    })
  } else {
    moment.locale('en')
  }
  app.$i18n.locale = payload
  state.user.language = payload
}

export const UPDATE_LANG = (state, payload) => {
  if (payload === 'vi') {
    moment.locale('vi', {
      months : 'tháng 1_tháng 2_tháng 3_tháng 4_tháng 5_tháng 6_tháng 7_tháng 8_tháng 9_tháng 10_tháng 11_tháng 12'.split('_'),
      monthsShort : 'Th01_Th02_Th03_Th04_Th05_Th06_Th07_Th08_Th09_Th10_Th11_Th12'.split('_'),
      relativeTime : {
        future : '%s tới',
        past : '%s trước',
        s : 'Vài giây',
        m : '1 phút',
        mm : '%d phút',
        h : '1 giờ',
        hh : '%d giờ',
        d : '1 ngày',
        dd : '%d ngày',
        M : '1 tháng',
        MM : '%d tháng',
        y : '1 năm',
        yy : '%d năm'
      },
      longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM [năm] YYYY',
        LLL : 'D MMMM [năm] YYYY HH:mm',
        LLLL : 'dddd, D MMMM [năm] YYYY HH:mm',
        l : 'DD/M/YYYY',
        ll : 'D MMM YYYY',
        lll : 'D MMM YYYY HH:mm',
        llll : 'ddd, D MMM YYYY HH:mm'
      },
    })
  } else {
    moment.locale('en')
  }
  state.user.language = payload
}

/**
 * Clear each property, one by one, so reactivity still works.
 *
 * (ie. clear out state.auth.isLoggedIn so Navbar component automatically reacts to logged out state,
 * and the Navbar menu adjusts accordingly)
 *
 * TODO: use a common import of default state to reset these values with.
 */
export const CLEAR_ALL_DATA = (state) => {
}
