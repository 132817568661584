<template>
    <span>{{ tweeningValue | formatNumber}}</span>
</template>


<script>
    var TWEEN = require('@tweenjs/tween.js');
    export default {
        name: 'AnimatedInteger',
        props: {
            value: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                tweeningValue: 0
            }
        },
        watch: {
            value: function (newValue, oldValue) {
                this.tween(oldValue, newValue)
            }
        },
        mounted() {
            this.tween(0, this.value)
        },
        methods: {
            tween(startValue, endValue) {
                var vm = this
                function animate () {
                    if (TWEEN.update()) {
                        requestAnimationFrame(animate)
                    }
                }

                new TWEEN.Tween({ tweeningValue: startValue })
                    .to({ tweeningValue: endValue }, 2000)
                    .onUpdate(function (object) {
                        vm.tweeningValue = object.tweeningValue
                    })
                    .start()

                animate()
            }
        }
    }
</script>