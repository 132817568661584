import Vue from 'vue'
import VueI18n from 'vue-i18n'
import vi from './vn.json'
import en from './en.json'
import enLocale from 'element-ui/lib/locale/lang/en'
import vnLocale from 'element-ui/lib/locale/lang/vi'
import store from '../store'

Vue.use(VueI18n)

const messages = {
  en: {
    ...en,
    ...enLocale // Or use `Object.assign({ message: 'hello' }, enLocale)`
  },
  vi: {
    ...vi,
    ...vnLocale
  }
}

if(window.localStorage.getItem('auth_key') !== null) {
  var i18n = new VueI18n({
    locale: JSON.parse(window.localStorage.getItem('auth_key')).user.language || 'vi', // set locale
    messages,
    fallbackLocale: 'en'
  })
} else {
  if (navigator.language !== 'vi') {
    var i18n = new VueI18n({
      locale: 'en', // set locale
      messages,
      fallbackLocale: 'en'
    })
  } else {
    var i18n = new VueI18n({
      locale: 'vi', // set locale
      messages,
      fallbackLocale: 'en'
    })
  }
}

export default i18n
