<template>
    <div>
        <h1 class="title-term">Introduction</h1>
        <div class="pb-3">
            <h3 class="description-term">CyStack Attack Map provides real-time tracking and detailed statistics of cyberattacks on websites and servers all over the world. The data was collected and analyzed by CyStack from a variety of sources including search engines, hacked website databases, social networks, etc.</h3>
        </div>

        <h1 class="title-term">Terms of use</h1>
        <div class="pb-3">
            <h3 class="description-term">- Data is provided to users for non-commercial and research purposes. You can obtain it by using our API, WebUI or reports.</h3>
            <h3 class="description-term">- Do not change the data when reissued.</h3>
            <h3 class="description-term ">- The API carries no warranty, no guarantee of its uptime and we reserve the right to change any aspect of the API at our own discretion at any time.</h3>
            <h3 class="description-term">- If you want to use the data for commercial purposes, contact us via the information below.</h3>
        </div>

        <h1 class="title-term">Contact</h1>
        <div class="">
            <h3 class="description-term">- Website: <a href="https://cystack.net" target="_blank" class="m-link">https://cystack.net</a></h3>
            <h3 class="description-term">- Email: contact@cystack.net</h3>
            <h3 class="description-term">- Address: 3rd floor, BigWin tower, Le Van Luong, Hanoi, Vietnam</h3>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'about',
    }
</script>