<template>
    <!-- begin:: Page -->
    <div class="m-grid m-grid--hor m-grid--root m-page" style="background:#191a30;">
        <!-- begin::Header -->
        <!-- begin::Header -->
        <header id="m_header" class="m-grid__item m-header " m-minimize="minimize" m-minimize-mobile="minimize"
                m-minimize-offset="10" m-minimize-mobile-offset="10">
            <div class="m-header__top">
                <div class="m-container m-container--fluid m-container--full-height m-page__container">
                    <div class="m-stack m-stack--ver m-stack--desktop">

                        <!-- begin::Brand -->
                        <div class="m-stack__item m-brand m-stack__item--left">
                            <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                                <div class="m-stack__item m-stack__item--middle m-brand__logo">
                                    <a href="https://cystack.net/" class="m-brand__logo-wrapper" target="_blank">
                                        <img alt="" src="../assets/img/logo/attack-logo.svg"
                                             class="m-brand__logo-default" style="height: 50px"/>
                                        <img alt="" src="../assets/img/logo/attack-logo.svg"
                                             class="m-brand__logo-inverse"
                                             style="height: 50px"/>
                                    </a>
                                </div>
                                <div class="m-stack__item m-stack__item--middle m-brand__tools">

                                    <!-- begin::Responsive Header Menu Toggler-->
                                    <a id="m_aside_header_menu_mobile_toggle" href="javascript:;"
                                       class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
                                        <span></span>
                                    </a>

                                    <!-- end::Responsive Header Menu Toggler-->

                                    <!-- begin::Topbar Toggler-->
                                    <!--<a id="m_aside_header_topbar_mobile_toggle" href="javascript:;"-->
                                       <!--class="m-brand__icon m&#45;&#45;visible-tablet-and-mobile-inline-block">-->
                                        <!--<i class="flaticon-more"></i>-->
                                    <!--</a>-->

                                    <!--end::Topbar Toggler-->
                                </div>
                            </div>
                        </div>
                        <!-- ed::Brand -->

                        <div class="m-stack__item m-stack__item--middle m-stack__item--fluid" style="width: 50%">
                            <div id="m_header_menu"
                                 class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-dark m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light">
                                <ul class="m-menu__nav  m-menu__nav--submenu-arrow">
                                    <router-link tag="li" class="m-menu__item" :to="{ name: 'realtime'}"
                                                 active-class="m-menu__item--active" aria-haspopup="true" :exact="true">
                                        <a class="m-menu__link ">
                                            <span class="m-menu__item-here"></span>
                                            <span class="m-menu__link-text">
													{{ $t("common.nav.realtime") }}
                                            </span>
                                        </a>
                                    </router-link>
                                    <router-link tag="li" class="m-menu__item" :to="{ name: 'home'}"
                                                 active-class="m-menu__item--active" aria-haspopup="true">
                                        <a class="m-menu__link ">
                                            <span class="m-menu__item-here"></span>
                                            <span class="m-menu__link-text">
													{{ $t("common.nav.statistics") }}
                                            </span>
                                        </a>
                                    </router-link>
                                    <router-link tag="li" class="m-menu__item" :to="{ name: 'widget'}"
                                                 active-class="m-menu__item--active" aria-haspopup="true">
                                        <a class="m-menu__link ">
                                            <span class="m-menu__item-here"></span>
                                            <span class="m-menu__link-text">
													{{ $t("common.nav.widget") }}
                                            </span>
                                        </a>
                                    </router-link>
                                    <li class="m-menu__item" @click="aboutDialog=true">
                                        <a class="m-menu__link ">
                                            <span class="m-menu__item-here"></span>
                                            <span class="m-menu__link-text">
													{{ $t("common.nav.about") }}
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- begin::Topbar -->
                        <div class="m-stack__item m-stack__item--right m-header-head" id="m_header_nav">
                            <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">

                            </div>
                        </div>

                        <!-- end::Topbar -->
                    </div>
                </div>
            </div>
            <div class="m-header__bottom" style="height: 0;">

            </div>
        </header>

        <!-- end::Header -->

        <!-- end::Header -->
        <!-- begin::Body -->
        <div class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop m-page__container m-body" style="background:#191a30;">
            <div class="m-grid__item m-grid__item--fluid m-wrapper" style="background: transparent">
                <div class="row">
                    <div class="col-xl-4 d-flex flex-column justify-content-center align-items-start" style="height: 80vh">
                        <div class="">
                            <div style="color: #C6DBEF" class="mb-5">Add the CyStackWidget to your site by adding the HTML code below.</div>
                            <el-input
                                    type="textarea"
                                    :rows="7"
                                    placeholder="Please input"
                                    v-model="textarea"
                                    readonly
                                    class="mb-3">
                            </el-input>
                            <button class="btn btn-primary btn-block text-uppercase _clickable" v-clipboard="textarea">Copy This Code</button>
                        </div>
                    </div>
                    <div class="col-xl-8" style="height: 80vh">
                        <WidgetScreen class="m-auto"></WidgetScreen>
                    </div>
                </div>
            </div>
            <el-dialog title="" :visible.sync="aboutDialog" custom-class="bg-about" :fullscreen="detectmobile()">
                <div slot="title">
                    <img src="../assets/img/logo/attack-logo.svg" alt="" style="height: 50px">
                </div>
                <About></About>
            </el-dialog>
        </div>

        <!-- begin::Footer -->
        <footer class="m-grid__item m-footer " style="background:#191a30;">
            <div class="m-container m-container--fluid m-container--full-height m-page__container">
                <div class="m-footer__wrapper">
                    <div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
                        <div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
								<span class="m-footer__copyright">
                                    Copyright © 2018
									<a href="https://cystack.net" target="_blank" class="m-link">CyStack</a>. All rights reserved.
								</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <!-- end::Footer -->
        <script2 src="/theme/vendors.bundle.js"></script2>
        <script2 src="/theme/scripts.bundle.js"></script2>
    </div>
    <!-- end:: Page -->
</template>

<script>
    // @ is an alias to /src
    import WidgetScreen from '../components/WidgetScreen.vue'
    import About from './About.vue'

    export default {
        name: 'realtime',
        data() {
            return {
                aboutDialog: false,
                textarea: "<!-- CyStack Attack Map provides real-time tracking and detailed statistics of cyberattacks on websites and servers all over the world. -->\n" +
                    "<iframe src=\"https://attacks.cystack.net/widget/_embed\" frameborder=\"0\" style=\"width: 100%;border: none;overflow: hidden;\" width=\"800\" height=\"600\"></iframe>"
            }
        },
        components: {
            WidgetScreen, About
        },
        created() {
        }
    }
</script>
