<template>
    <div>
        <div v-show="loading" class="loader-wrapper-screen">
            <!--<img class="rotate" src="/static/img/favicon.ico" alt="loading">-->
            <svg class="loader-inside-screen" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105.5px" height="124.6px" viewBox="0 0 105.5 124.6" style="enable-background:new 0 0 105.5 124.6;" xml:space="preserve" ><g id="Group-3"> <g> <path id="Fill-1" class="st000" d="M52.8,26.9l-8.9,4.9L2.5,54.2c10.3,48.6,47.7,69.1,50.3,70.4c2.5-1.3,40-21.7,50.2-70.4 L61.6,31.7L52.8,26.9z"/> </g></g><path id="Fill-4" class="st100" d="M52.8,18l-8.9,4.9L1.1,46.1c0.4,2.8,0.9,5.5,1.4,8.1l41.4-22.4l8.9-4.9l8.8,4.8L103,54.2 c0.5-2.6,1-5.3,1.4-8.1L61.6,22.8L52.8,18z"/><path id="Fill-6" class="st200" d="M52.8,9.5L44,14.4L0.3,38.1c0.2,2.8,0.5,5.5,0.8,8.1l42.8-23.3l8.9-4.8l9,4.9l42.6,23.2 c0.3-2.6,0.7-5.3,0.9-8.1L61.5,14.3L52.8,9.5z"/><g id="Group-10"> <g> <path id="Fill-8" class="st300" d="M52.8,0.1L0,29.1c0,3,0.1,6,0.3,9L44,14.4l8.8-4.8l8.8,4.8l43.7,23.7c0.2-3,0.2-6,0.2-9.1 L52.8,0.1z"/> </g></g></svg>
        </div>
        <div>
            <div class="row" v-show="showMap">
                <div class="col-xl-4">
                    <div class="m-portlet m-portlet--bordered-semi m-portlet--full-height m-portlet--rounded">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Countries
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="m-scrollable m-scroller ps ps--active-y">
                                <div class="m-list-timeline m-list-timeline--skin-light">
                                    <div class="m-list-timeline__items">
                                        <div class="m-list-timeline__item" v-for="country in mapData.slice(0, 15)" v-if="country.value>0">
                                            <span class="m-list-timeline__text"><img :src="`/theme/images/flags/${country.id.toLowerCase()}.svg`" alt="icon" height="20px" width="30px"> <span class="pl-3">{{getCountryNameFromCode(country.id)}}</span></span>
                                            <span class="m-list-timeline__time">{{country.value | formatNumber}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8">
                    <div class="m-portlet m-portlet--bordered-semi m-portlet--full-height  m-portlet--rounded">
                        <div class="m-portlet__body">
                            <div class="hello map-dark position-relative" ref="mapdiv">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <div class="m-portlet m-portlet--bordered-semi m-portlet--full-height m-portlet--rounded">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Daily Attacks
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="hello2 full-height position-relative" ref="chartDaily">
                                <div class="loader-wrapper-component">
                                    <!--<img class="rotate" src="/static/img/favicon.ico" alt="loading">-->
                                    <svg class="loader-inside-component" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105.5px" height="124.6px" viewBox="0 0 105.5 124.6" style="enable-background:new 0 0 105.5 124.6;" xml:space="preserve" ><g id="Group-3"> <g> <path id="Fill-1" class="st000" d="M52.8,26.9l-8.9,4.9L2.5,54.2c10.3,48.6,47.7,69.1,50.3,70.4c2.5-1.3,40-21.7,50.2-70.4 L61.6,31.7L52.8,26.9z"/> </g></g><path id="Fill-4" class="st100" d="M52.8,18l-8.9,4.9L1.1,46.1c0.4,2.8,0.9,5.5,1.4,8.1l41.4-22.4l8.9-4.9l8.8,4.8L103,54.2 c0.5-2.6,1-5.3,1.4-8.1L61.6,22.8L52.8,18z"/><path id="Fill-6" class="st200" d="M52.8,9.5L44,14.4L0.3,38.1c0.2,2.8,0.5,5.5,0.8,8.1l42.8-23.3l8.9-4.8l9,4.9l42.6,23.2 c0.3-2.6,0.7-5.3,0.9-8.1L61.5,14.3L52.8,9.5z"/><g id="Group-10"> <g> <path id="Fill-8" class="st300" d="M52.8,0.1L0,29.1c0,3,0.1,6,0.3,9L44,14.4l8.8-4.8l8.8,4.8l43.7,23.7c0.2-3,0.2-6,0.2-9.1 L52.8,0.1z"/> </g></g></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="m-portlet m-portlet--bordered-semi m-portlet--full-height  m-portlet--rounded">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Top Level Domains
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="hello2 full-height position-relative" ref="chartTLD">
                                <div class="loader-wrapper-component">
                                    <!--<img class="rotate" src="/static/img/favicon.ico" alt="loading">-->
                                    <svg class="loader-inside-component" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105.5px" height="124.6px" viewBox="0 0 105.5 124.6" style="enable-background:new 0 0 105.5 124.6;" xml:space="preserve" ><g id="Group-3"> <g> <path id="Fill-1" class="st000" d="M52.8,26.9l-8.9,4.9L2.5,54.2c10.3,48.6,47.7,69.1,50.3,70.4c2.5-1.3,40-21.7,50.2-70.4 L61.6,31.7L52.8,26.9z"/> </g></g><path id="Fill-4" class="st100" d="M52.8,18l-8.9,4.9L1.1,46.1c0.4,2.8,0.9,5.5,1.4,8.1l41.4-22.4l8.9-4.9l8.8,4.8L103,54.2 c0.5-2.6,1-5.3,1.4-8.1L61.6,22.8L52.8,18z"/><path id="Fill-6" class="st200" d="M52.8,9.5L44,14.4L0.3,38.1c0.2,2.8,0.5,5.5,0.8,8.1l42.8-23.3l8.9-4.8l9,4.9l42.6,23.2 c0.3-2.6,0.7-5.3,0.9-8.1L61.5,14.3L52.8,9.5z"/><g id="Group-10"> <g> <path id="Fill-8" class="st300" d="M52.8,0.1L0,29.1c0,3,0.1,6,0.3,9L44,14.4l8.8-4.8l8.8,4.8l43.7,23.7c0.2-3,0.2-6,0.2-9.1 L52.8,0.1z"/> </g></g></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xxl-6">
                    <div class="m-portlet m-portlet--bordered-semi m-portlet--full-height m-portlet--rounded">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Content Management Systems
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="hello2 position-relative" ref="divCMS">
                                <div class="loader-wrapper-component">
                                    <!--<img class="rotate" src="/static/img/favicon.ico" alt="loading">-->
                                    <svg class="loader-inside-component" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105.5px" height="124.6px" viewBox="0 0 105.5 124.6" style="enable-background:new 0 0 105.5 124.6;" xml:space="preserve" ><g id="Group-3"> <g> <path id="Fill-1" class="st000" d="M52.8,26.9l-8.9,4.9L2.5,54.2c10.3,48.6,47.7,69.1,50.3,70.4c2.5-1.3,40-21.7,50.2-70.4 L61.6,31.7L52.8,26.9z"/> </g></g><path id="Fill-4" class="st100" d="M52.8,18l-8.9,4.9L1.1,46.1c0.4,2.8,0.9,5.5,1.4,8.1l41.4-22.4l8.9-4.9l8.8,4.8L103,54.2 c0.5-2.6,1-5.3,1.4-8.1L61.6,22.8L52.8,18z"/><path id="Fill-6" class="st200" d="M52.8,9.5L44,14.4L0.3,38.1c0.2,2.8,0.5,5.5,0.8,8.1l42.8-23.3l8.9-4.8l9,4.9l42.6,23.2 c0.3-2.6,0.7-5.3,0.9-8.1L61.5,14.3L52.8,9.5z"/><g id="Group-10"> <g> <path id="Fill-8" class="st300" d="M52.8,0.1L0,29.1c0,3,0.1,6,0.3,9L44,14.4l8.8-4.8l8.8,4.8l43.7,23.7c0.2-3,0.2-6,0.2-9.1 L52.8,0.1z"/> </g></g></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-6">
                    <div class="m-portlet m-portlet--bordered-semi m-portlet--full-height  m-portlet--rounded">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Programing Languages
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="hello2 position-relative" ref="divFramework">
                                <div class="loader-wrapper-component">
                                    <!--<img class="rotate" src="/static/img/favicon.ico" alt="loading">-->
                                    <svg class="loader-inside-component" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105.5px" height="124.6px" viewBox="0 0 105.5 124.6" style="enable-background:new 0 0 105.5 124.6;" xml:space="preserve" ><g id="Group-3"> <g> <path id="Fill-1" class="st000" d="M52.8,26.9l-8.9,4.9L2.5,54.2c10.3,48.6,47.7,69.1,50.3,70.4c2.5-1.3,40-21.7,50.2-70.4 L61.6,31.7L52.8,26.9z"/> </g></g><path id="Fill-4" class="st100" d="M52.8,18l-8.9,4.9L1.1,46.1c0.4,2.8,0.9,5.5,1.4,8.1l41.4-22.4l8.9-4.9l8.8,4.8L103,54.2 c0.5-2.6,1-5.3,1.4-8.1L61.6,22.8L52.8,18z"/><path id="Fill-6" class="st200" d="M52.8,9.5L44,14.4L0.3,38.1c0.2,2.8,0.5,5.5,0.8,8.1l42.8-23.3l8.9-4.8l9,4.9l42.6,23.2 c0.3-2.6,0.7-5.3,0.9-8.1L61.5,14.3L52.8,9.5z"/><g id="Group-10"> <g> <path id="Fill-8" class="st300" d="M52.8,0.1L0,29.1c0,3,0.1,6,0.3,9L44,14.4l8.8-4.8l8.8,4.8l43.7,23.7c0.2-3,0.2-6,0.2-9.1 L52.8,0.1z"/> </g></g></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-6">
                    <div class="m-portlet m-portlet--bordered-semi m-portlet--full-height  m-portlet--rounded">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Operating Systems
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="hello2 position-relative" ref="divSystem">
                                <div class="loader-wrapper-component">
                                    <!--<img class="rotate" src="/static/img/favicon.ico" alt="loading">-->
                                    <svg class="loader-inside-component" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105.5px" height="124.6px" viewBox="0 0 105.5 124.6" style="enable-background:new 0 0 105.5 124.6;" xml:space="preserve" ><g id="Group-3"> <g> <path id="Fill-1" class="st000" d="M52.8,26.9l-8.9,4.9L2.5,54.2c10.3,48.6,47.7,69.1,50.3,70.4c2.5-1.3,40-21.7,50.2-70.4 L61.6,31.7L52.8,26.9z"/> </g></g><path id="Fill-4" class="st100" d="M52.8,18l-8.9,4.9L1.1,46.1c0.4,2.8,0.9,5.5,1.4,8.1l41.4-22.4l8.9-4.9l8.8,4.8L103,54.2 c0.5-2.6,1-5.3,1.4-8.1L61.6,22.8L52.8,18z"/><path id="Fill-6" class="st200" d="M52.8,9.5L44,14.4L0.3,38.1c0.2,2.8,0.5,5.5,0.8,8.1l42.8-23.3l8.9-4.8l9,4.9l42.6,23.2 c0.3-2.6,0.7-5.3,0.9-8.1L61.5,14.3L52.8,9.5z"/><g id="Group-10"> <g> <path id="Fill-8" class="st300" d="M52.8,0.1L0,29.1c0,3,0.1,6,0.3,9L44,14.4l8.8-4.8l8.8,4.8l43.7,23.7c0.2-3,0.2-6,0.2-9.1 L52.8,0.1z"/> </g></g></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-6">
                    <div class="m-portlet m-portlet--bordered-semi m-portlet--full-height  m-portlet--rounded">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Web Servers
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="hello2 position-relative" ref="divServer">
                                <div class="loader-wrapper-component">
                                    <!--<img class="rotate" src="/static/img/favicon.ico" alt="loading">-->
                                    <svg class="loader-inside-component" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="105.5px" height="124.6px" viewBox="0 0 105.5 124.6" style="enable-background:new 0 0 105.5 124.6;" xml:space="preserve" ><g id="Group-3"> <g> <path id="Fill-1" class="st000" d="M52.8,26.9l-8.9,4.9L2.5,54.2c10.3,48.6,47.7,69.1,50.3,70.4c2.5-1.3,40-21.7,50.2-70.4 L61.6,31.7L52.8,26.9z"/> </g></g><path id="Fill-4" class="st100" d="M52.8,18l-8.9,4.9L1.1,46.1c0.4,2.8,0.9,5.5,1.4,8.1l41.4-22.4l8.9-4.9l8.8,4.8L103,54.2 c0.5-2.6,1-5.3,1.4-8.1L61.6,22.8L52.8,18z"/><path id="Fill-6" class="st200" d="M52.8,9.5L44,14.4L0.3,38.1c0.2,2.8,0.5,5.5,0.8,8.1l42.8-23.3l8.9-4.8l9,4.9l42.6,23.2 c0.3-2.6,0.7-5.3,0.9-8.1L61.5,14.3L52.8,9.5z"/><g id="Group-10"> <g> <path id="Fill-8" class="st300" d="M52.8,0.1L0,29.1c0,3,0.1,6,0.3,9L44,14.4l8.8-4.8l8.8,4.8l43.7,23.7c0.2-3,0.2-6,0.2-9.1 L52.8,0.1z"/> </g></g></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import * as am4maps from "@amcharts/amcharts4/maps";
    import am4geodata_worldHigh from "@amcharts/amcharts4-geodata/worldHigh";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";
    import am4themes_dark from "@amcharts/amcharts4/themes/dark";
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_dark);
    import * as moment from "moment";
    import CONSTANT from "../constant";
    export default {
        props: ['dateTimePicker', 'filter', 'amount', 'detailAmount', 'dataCountry', 'statusFilter', 'dataTLD', 'dataCMS', 'dataSystem', 'dataServer', 'dataLanguage'],
        name: 'MapChart',
        data() {
            return {
                chart: null,
                map: null,
                moment: moment,
                loadingMap: false,
                mapData: [],
                dailyData: [],
                topLevelDomainData: [],
                toDayStart: moment().startOf('day').utc().unix(),
                toDayEnd: moment().endOf('day').utc().unix(),
                monthStart: moment().startOf('month').utc().unix(),
                monthEnd: moment().endOf('month').utc().unix(),
                weekStart: moment().startOf('week').utc().unix(),
                weekEnd: moment().endOf('week').utc().unix(),
                fromTime: this.formatDatetime(this.dateTimePicker[0]),
                toTime: this.formatDatetime(this.dateTimePicker[1]),
                loading: true,
                firstTime: true,
                showMap: true,
                countries: []
            }
        },
        methods: {
            createMap() {
                // Create map instance
                var map = am4core.create(this.$refs.mapdiv, am4maps.MapChart);

                // Set map definition
                map.geodata = am4geodata_worldHigh;

                // Set projection
                map.projection = new am4maps.projections.Miller();
                // Create map polygon series
                var polygonSeries = map.series.push(new am4maps.MapPolygonSeries());

                // Make map load polygon (like country names) data from GeoJSON
                polygonSeries.useGeodata = true;
                // map.background  =  am4core.color("#E2EDFF")
                // polygonSeries.fill = am4core.color("#E2EDFF")
                map.chartContainer.background = am4core.color("#E2EDFF");

                // Configure series
                var polygonTemplate = polygonSeries.mapPolygons.template;
                polygonTemplate.tooltipText = "{name} {value}";
                polygonTemplate.fill = am4core.color("#C6DBEF");
                polygonTemplate.adapter.add("stroke", function (fill, target) {
                    return am4core.color("#E2EDFF")
                });
                polygonTemplate.strokeWidth = 0.8;
                // Create hover state and set alternative fill color
                var hs = polygonTemplate.states.create("hover");
                hs.properties.fill = am4core.color("#6177E4");

                // Remove Antarctica
                polygonSeries.exclude = ["AQ"];

                // Add heat rule
                polygonSeries.heatRules.push({
                    "property": "fill",
                    "target": polygonSeries.mapPolygons.template,
                    "min": am4core.color("#aacaff"),
                    "max": am4core.color("#0363ff")
                });

                let heatLegend = map.createChild(am4maps.HeatLegend);
                heatLegend.series = polygonSeries;
                heatLegend.width = am4core.percent(100);
                heatLegend.valueAxis.renderer.labels.template.marginTop = 10;
                heatLegend.marginTop = 30;

                polygonSeries.mapPolygons.template.events.on("over", function(ev) {
                    if (!isNaN(ev.target.dataItem.value)) {
                        heatLegend.valueAxis.showTooltipAt(ev.target.dataItem.value)
                    }
                    else {
                        heatLegend.valueAxis.hideTooltip();
                    }
                });

                polygonSeries.mapPolygons.template.events.on("out", function(ev) {
                    heatLegend.valueAxis.hideTooltip();
                });

                map.chartContainer.wheelable = false;
                map.seriesContainer.draggable = false;
                map.maxZoomLevel = 1;

                // Add expectancy data
                polygonSeries.data = JSON.parse(JSON.stringify(this.mapData));

            },
            createChartTLD(data, location, title) {
                let chart = am4core.create(location, am4charts.XYChart);
                // chart.data = data
                chart.data = data;
                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "name";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.grid.template.strokeWidth = 0;
                categoryAxis.renderer.minGridDistance = 20;
                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.cursorTooltipEnabled = false;

                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "value";
                series.dataFields.categoryX = "name";
                series.columns.template.tooltipText = "{categoryX}: {valueY}";
                series.columns.template.fill = "#0476E9";

                chart.chartContainer.wheelable = false;
                chart.chartContainer.maxZoomLevel = 1;
                chart.hiddenState.properties.opacity = 0;
                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                chart.cursor.lineY.disabled = true;

                chart.exporting.menu = new am4core.ExportMenu();
                chart.exporting.menu.align = "right";
                chart.exporting.menu.verticalAlign = "top";
                chart.exporting.menu.items = [{
                    "label": "...",
                    "menu": [
                        { "type": "png", "label": "PNG" },
                        { "type": "jpg", "label": "JPG" },
                        { "type": "svg", "label": "SVG" }
                    ]
                }];
                chart.exporting.setFormatOptions("png", { scale: 2 });
                chart.exporting.setFormatOptions("jpg", { scale: 2 });
                chart.exporting.filePrefix = `CyStack-${this.fromTime}-${this.toTime}-${title}`

            },
            createPieChart(data, location, title) {
                var chart = am4core.create(location, am4charts.PieChart);
                chart.data = data.slice(0,10);
                var pieSeries = chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = "value";
                pieSeries.dataFields.category = "name";
                pieSeries.labels.template.text = "{category} {value.percent.formatNumber('#.00')}%";

                // Add a legend
                chart.legend = new am4charts.Legend();
                chart.legend.position = "right";
                chart.legend.labels.template.fill = "#C6DBEF";
                chart.legend.valueLabels.template.fill = "#C6DBEF";
                chart.legend.valueLabels.template.text = "{value.percent.formatNumber('#.00')}%";
                chart.legend.labels.template.wrap = true;
                chart.legend.labels.template.maxWidth = 150;

                // pieSeries.labels.template.text = "{category}";
                // pieSeries.slices.template.tooltipText = "{category}: {value.value}";
                // chart.legend = new am4charts.Legend();
                // chart.legend.valueLabels.template.text = "{value.value}";
                pieSeries.labels.template.disabled = true;
                pieSeries.ticks.template.disabled = true;
                // pieSeries.alignLabels = false;
                // pieSeries.labels.template.radius = -60;
                // pieSeries.labels.template.fill = am4core.color("white");
                chart.exporting.menu = new am4core.ExportMenu();
                chart.exporting.menu.align = "left";
                chart.exporting.menu.verticalAlign = "top";
                chart.exporting.menu.items = [{
                    "label": "...",
                    "menu": [
                        { "type": "png", "label": "PNG" },
                        { "type": "jpg", "label": "JPG" },
                        { "type": "svg", "label": "SVG" }
                    ]
                }];
                chart.exporting.setFormatOptions("png", { scale: 2 });
                chart.exporting.setFormatOptions("jpg", { scale: 2 });
                chart.exporting.filePrefix = `CyStack-${this.fromTime}-${this.toTime}-${title}`;

                var colorSet = new am4core.ColorSet();
                colorSet.list = ["#0476E9", "#34A2FF", "#6177E4", "#E3F2FF", "#D291BC", "#FFCDCD", "#005792", "#FC6B3F", "#F52C4A", "#73DBC4"].map(function(color) {
                    return new am4core.color(color);
                });
                pieSeries.colors = colorSet;
            },
            createDailyChart(data, location, title) {
                let chart = am4core.create(location, am4charts.XYChart);
                chart.data = data;
                chart.dateFormatter.dateFormat = "yyyy-MM-dd";
                let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                dateAxis.dataFields.category = "name";
                dateAxis.renderer.grid.template.strokeWidth = 0;
                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.cursorTooltipEnabled = false;

                let series = chart.series.push(new am4charts.LineSeries());
                series.dataFields.valueY = "value";
                series.dataFields.dateX = "time";
                series.tooltipText = "{dateX}: {valueY}";
                // series.columns.template.fill = "#0476E9"
                chart.chartContainer.wheelable = true;
                chart.chartContainer.maxZoomLevel = 1;
                // Add cursor
                chart.cursor = new am4charts.XYCursor();
                chart.cursor.lineY.disabled = true;
                chart.hiddenState.properties.opacity = 0;

                chart.exporting.menu = new am4core.ExportMenu();
                chart.exporting.menu.align = "right";
                chart.exporting.menu.verticalAlign = "top";
                chart.exporting.menu.items = [{
                    "label": "...",
                    "menu": [
                        { "type": "png", "label": "PNG" },
                        { "type": "jpg", "label": "JPG" },
                        { "type": "svg", "label": "SVG" }
                    ]
                }];
              chart.exporting.setFormatOptions("png", { scale: 2 });
              chart.exporting.setFormatOptions("jpg", { scale: 2 });
              chart.exporting.filePrefix = `CyStack-${this.fromTime}-${this.toTime}-${title}`

            },
            getMapData(from, to, filter) {
                let query= "";
                for (let property in filter) {
                    if(filter[property]) {
                        query+=`&${property}=${filter[property]}`
                    }
                }
                this.loadingMap = true;
                return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/country${from?'?from='+from+'&to='+to:''}${query?query:''}`)
                    .then(response => {
                        this.mapData = response.data;
                        if(this.firstTime) this.$emit('update:dataCountry', response.data);
                        this.mapData = this.mapData.map((location) => {
                            return {"id": location.name, "value": location.value}
                        });
                        if(!this.firstTime) this.createMap();
                        return response.data;
                    }, error => {

                    })
            },
            getTLDData(from, to, filter) {
                let query= "";
                for (let property in filter) {
                    if(filter[property]) {
                        query+=`&${property}=${filter[property]}`
                    }
                }
                return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/tld${from?'?from='+from+'&to='+to:''}${query?query:''}`)
                    .then(response => {
                        if(this.firstTime) this.$emit('update:dataTLD', response.data);

                        this.topLevelDomainData = response.data;
                        this.topLevelDomainData = this.topLevelDomainData.map(data => {
                            return {name: "."+data.name, value: data.value}
                        });
                        if(!this.firstTime) this.createChartTLD(this.topLevelDomainData.slice(0, 19), this.$refs.chartTLD, 'TLDs')
                    }, error => {

                    })
            },
            getDailyData(from, to, filter) {
                let query= "";
                for (let property in filter) {
                    if(filter[property]) {
                        query+=`&${property}=${filter[property]}`
                    }
                }
                return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/daily${from?'?from='+from+'&to='+to:''}${query?query:''}`)
                    .then(response => {
                        this.dailyData = response.data;
                        this.dailyData = this.dailyData.map(e => {
                            return {
                                "time": e.time*1000,
                                "value": e.value
                            }
                        });
                        if(!this.firstTime) this.createDailyChart(this.dailyData, this.$refs.chartDaily, '')
                    }, error => {

                    })
            },
            getCMS(from, to, filter) {
                let query= "";
                for (let property in filter) {
                    if(filter[property]) {
                        query+=`&${property}=${filter[property]}`
                    }
                }
                return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/cms${from?'?from='+from+'&to='+to:''}${query?query:''}`)
                    .then(response => {
                        if(this.firstTime) this.$emit('update:dataCMS', response.data);
                        if(!this.firstTime) this.createPieChart(response.data, this.$refs.divCMS, 'CMS')
                    }, error => {

                    })
            },
            getSystem(from, to, filter) {
                let query= "";
                for (let property in filter) {
                    if(filter[property]) {
                        query+=`&${property}=${filter[property]}`
                    }
                }
                return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/system${from?'?from='+from+'&to='+to:''}${query?query:''}`)
                    .then(response => {
                        if(this.firstTime) this.$emit('update:dataSystem', response.data);
                        if(!this.firstTime) this.createPieChart(response.data, this.$refs.divSystem, 'OS')
                    }, error => {

                    })
            },
            getLanguage(from, to, filter) {
                let query= "";
                for (let property in filter) {
                    if(filter[property]) {
                        query+=`&${property}=${filter[property]}`
                    }
                }
                return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/language${from?'?from='+from+'&to='+to:''}${query?query:''}`)
                    .then(response => {
                        if(this.firstTime) this.$emit('update:dataLanguage', response.data);
                        if(!this.firstTime) this.createPieChart(response.data, this.$refs.divFramework, 'Language')
                    }, error => {

                    })
            },
            getServer(from, to, filter) {
                let query= "";
                for (let property in filter) {
                    if(filter[property]) {
                        query+=`&${property}=${filter[property]}`
                    }
                }
                return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/web_server${from?'?from='+from+'&to='+to:''}${query?query:''}`)
                    .then(response => {
                        if(this.firstTime) this.$emit('update:dataServer', response.data);
                        if(!this.firstTime) this.createPieChart(response.data, this.$refs.divServer, 'Server')
                    }, error => {

                    })
            },
            getCountries() {
                return this.$http.get(`${CONSTANT.API_HREF_BASE}/resources/countries`)
                    .then(response => {
                        this.countries = response.data
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
            },
            getCountryNameFromCode(code) {
                for (let i = 0; i < this.countries.length; i++) {
                    if (this.countries[i].country_code.toLowerCase() === code.toLowerCase()) {
                        return this.countries[i].country_name
                    }
                }
                return code
            },
            renderChart() {
                this.loading = true;
                this.$emit('update:statusFilter', false);
                const ONE_DAY = 24 * 60 * 60
                Promise.all([
                    this.getGeneral(this.fromTime, this.toTime + ONE_DAY, this.filter),
                    this.getDetailGeneral(this.fromTime, this.toTime + ONE_DAY, this.filter),
                    this.getDailyData(this.fromTime, this.toTime + ONE_DAY, this.filter),
                    this.getMapData(this.fromTime, this.toTime + ONE_DAY, this.filter),
                    this.getTLDData(this.fromTime, this.toTime + ONE_DAY, this.filter),
                    this.getCMS(this.fromTime, this.toTime + ONE_DAY, this.filter),
                    this.getLanguage(this.fromTime, this.toTime + ONE_DAY, this.filter),
                    this.getSystem(this.fromTime, this.toTime + ONE_DAY, this.filter),
                    this.getServer(this.fromTime, this.toTime + ONE_DAY, this.filter)
                ]).then(() => {
                    this.loading = false
                })
            },
            formatDatetime(datetime) {
                if(datetime) return moment(datetime).unix() + moment(datetime).utcOffset()*60;
                return ''
            },
            getGeneral(from, to, filter) {
                let query= "";
                for (let property in filter) {
                    if(filter[property]) {
                        query+=`&${property}=${filter[property]}`
                    }
                }
                return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/general${from ?'?from='+from+'&to='+to:''}${query?query:''}`)
                    .then(response => {
                        this.$emit('update:amount', response.data.amount)
                    }).catch(function (error) {
                        // handle error
                        console.log(error)
                    })
            },
            getDetailGeneral(from, to, filter) {
                let query= "";
                for (let property in filter) {
                    if(filter[property]) {
                        query+=`&${property}=${filter[property]}`
                    }
                }
                return this.$http.get(`${CONSTANT.SERVICE_HREF_STATISTICS}/status${from ?'?from='+from+'&to='+to:''}${query?query:''}`)
                    .then(response => {
                        this.$emit('update:amountDetail', response.data)
                    }).catch(function (error) {
                        // handle error
                        console.log(error)
                    })
            },
            applyFilter() {
                this.$emit('update:statusFilter', false);
                if(this.filter.country) {
                    this.showMap = false
                } else {
                    this.showMap = true
                }
                this.renderChart()
            }
        },
        mounted() {
        },
        created() {
            this.getCountries().then(() => {
                this.$emit('filter', {
                    country: "",
                    tld: "",
                    cms: "",
                    system: "",
                    web_server: "",
                    language: "",
                });
                Promise.all([
                    this.getMapData(),
                    this.getTLDData(),
                    this.getLanguage(),
                    this.getSystem(),
                    this.getServer(),
                    this.getCMS()
                ]).then(() => {
                    this.firstTime = false
                    this.renderChart()
                })
            })
        },
        watch: {
            // whenever dateTimePicker changes, this function will run
            dateTimePicker: function (newRange, oldRange) {
                if(newRange) {
                    this.fromTime = this.formatDatetime(newRange[0]);
                    this.toTime = this.formatDatetime(newRange[1]);
                    this.renderChart()
                }
            },
            'loading': (newValue, oldValue) => {
                if(newValue === true) {
                    document.body.style.overflowY = "hidden";
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                } else {
                    document.body.style.overflowY = "auto";
                }
            }
        },
        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose();
            }
            if (this.map) {
                this.map.dispose();
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .hello {
        width: 100%;
        height: 75vh;
    }
    .hello2 {
        width: 100%;
        height: 440px;
    }
    .loader-wrapper-component {
        position: absolute;
        z-index: 999;
        /*background-color: hsla(0,0%,100%,0.1);*/
        background: transparent;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity 2s;
    }

    .loader-inside-component {
        display: block;
        position: relative;
        left: 50%;
        top: 45%;
        width: 100px;
        height: 100px;
        margin: -50px 0 0 -50px;
    }
    .map-dark{
        /*background: black;*/
    }
</style>
