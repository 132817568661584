import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Axios from 'axios'
import Element from 'element-ui'
import './element-variables.scss'
import i18n from './lang/i18n'
import VueClipboards from 'vue-clipboards'

// Google tag manager exists ?!
import VueGtm from 'vue-gtm';

Vue.use(VueGtm, {
    id: 'GTM-T7CXHKM'
});

let numeral = require('numeral')

Vue.config.productionTip = false

Vue.prototype.$http = Axios;
Vue.filter('formatNumber', function (value) {
    return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
});
Vue.filter('formatAvgNumber', function (value) {
    return numeral(value).format('0.00') // displaying other groupings/separators is possible, look at the docs
});

Vue.use(Element, {
    i18n: (key, value) => i18n.t(key, value)
})
Vue.use(VueClipboards)

Vue.mixin({
    methods: {
        changeLang (value) {
            this.$store.dispatch('SetLang', value).then(() => {
                if(this.$store.state.auth.isLoggedIn) this.$store.dispatch('LoadCurrentIntercom')
            })
        },
        detectmobile() {
            if( navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)
            ) return true;
            return false;
        },
    }
})
const app  = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

import('./assets/_app.css')
export default app
