import Vue from 'vue'
import CONSTANT from '../constant'
import { Message } from 'element-ui'
import app from '../main'
import router from '../router'
const CURRENT_USER_INFO_URL = CONSTANT.SERVICE_HREF_BASE + '/me'

export const LoadCurrentUser = (context) => {
  // const user = context.state.user
  return Vue.http.get(CURRENT_USER_INFO_URL).then((response) => {
    context.commit('UPDATE_USER', response.body)
  })
}
export const LoadCurrentIntercom = (context) => {
  return Vue.http.get(CURRENT_USER_INFO_URL+'/intercom').then((response) => {
    window.intercomSettings = response.body;
    context.commit('UPDATE_USER_INTERCOM', response.body)
  })
}

export const LoadNotification = (context) => {
  // const user = context.state.user
  return Vue.http.get(CONSTANT.SERVICE_HREF_NOTIFICATIONS).then((response) => {
    context.commit('UPDATE_NOTIFICATION', response.body)
  })
}

export const GetWebsiteInfo = (context, id) => {
  return Vue.http.get(CONSTANT.SERVICE_HREF_TARGETS+'/'+id).then((response) => {
    if(response.body.verification) {
      context.commit('UPDATE_TARGET', response.body)
    } else {
      router.push({'name': 'listWebsites'})
    }
  }, error => {
    router.push({'name': 'listWebsites'})
  })
}

export const GetWebsites = (context) => {
  return Vue.http.get(CONSTANT.SERVICE_HREF_TARGETS+'?pagging=0&verification=1').then((response) => {
    context.commit('UPDATE_WEBSITES', response.body)
    Intercom('update', {"website_verified_number": response.body.length});
    return (response.body)
  })
}
export const GetConfigProtecting = (context, payload) => {
  if(payload.firstTime) {
    context.commit('UPDATE_LOADING', true)
  }
  return Vue.http.get(CONSTANT.SERVICE_HREF_PROTECTING+'/website/'+payload.id).then((response) => {
    Intercom('update', {"protecting_status": response.body.status});
    Intercom('update', {"protecting_use_https": response.body.https.new});
    context.commit('UPDATE_PROTECTING', response.body)
    context.commit('UPDATE_LOADING', false)
    return (response.body)
  }, error => {
    context.commit('UPDATE_LOADING', false)
  })
}
export const GetConfigMonitoring = (context, payload) => {
  if(payload.firstTime) {
    context.commit('UPDATE_LOADING', true)
  }
  return Vue.http.get(CONSTANT.SERVICE_HREF_MONITORING+'/website/'+payload.id).then((response) => {
    Intercom('update', {"monitoring_uptime_activated": response.body.categories.uptime.activated});
    Intercom('update', {"monitoring_ssl_activated": response.body.categories.ssl.activated});
    Intercom('update', {"monitoring_dns_activated": response.body.categories.dns.activated});

    context.commit('UPDATE_MONITORING', response.body)
    context.commit('UPDATE_LOADING', false)
    return response
  }, error => {return error})
}
export const GetApps = (context) => {
  return Vue.http.get(CONSTANT.SERVICE_HREF_APPS).then((response) => {
    context.commit('UPDATE_APPS', response.body)
  })
}
export const GetNodes = (context) => {
  return Vue.http.get(CONSTANT.SERVICE_HREF_MONITORING+'/nodes').then((response) => {
    context.commit('UPDATE_NODES', response.body)
  })
}
export const GetCards = (context) => {
  return Vue.http.get(CONSTANT.SERVICE_HREF_PAYMENT+'/subscriptions/cards').then((response) => {
    context.commit('UPDATE_CARDS', response.body)
  })
}
export const SetLang = (context, payload) => {
  context.commit('SET_LANG', payload)
  if (context.state.auth.isLoggedIn) {
    let data = Object.assign({}, context.state.user)
    data.language = payload
    Intercom('update', {"language_override": payload});
    return Vue.http.put(CURRENT_USER_INFO_URL, data).then((response) => {
    })
  }

}

export const UpdateLang = (context, payload) => {
  Intercom('update', {"language_override": payload});
  context.commit('UPDATE_LANG', payload)
}
